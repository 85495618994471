@media screen and (max-width: 414px) {
    .backIcon {
    /* margin-right: 5px; */
    color: rgba(0, 56, 107, .8);
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 4px;
    display: inline-flex; /* To ensure the box only takes the necessary width */
    align-items: center; /* To vertically center the icon in the box */
    background-color: #fff;
    border-color: rgba(0, 56, 107, .8);
  }
}