.backgroundImg {
  width: 100%;
  min-height: calc(100vh - (0rem));
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.backgroundImg img {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.boxContent {
  display: flex;
  flex-direction: column;
}
.contentImage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contentImage img {
  object-fit: contain;
  width: 100%;
}
