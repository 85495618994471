.contentNews {
    display: flex;
}

.allNews {
    width: calc(70% - 2rem);
    margin-right: 2rem;
}

.latestNews {
    width: 30%;
    padding: 0 1rem 1rem 1rem;
    border-left: 1px solid #E5E5E5;
}

.news {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.newsImg {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 1rem 0;
}

.newsContent {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  word-break: break-word;
}

.news .content p {
    width: 100%;
}

.isPreview {
  width: calc(100% - 100px);
}

.news img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.newsTitle {
  font-size: var(--hdText);
  font-weight: bold;
  margin-bottom: 8px;
}

.newsExt {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 30%;

  font-size: 11px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.4);
  margin-right: 1.5rem;
  padding: 4px 0px 0px;
  border-top: 1px dotted rgba(0, 0, 0, 0.2);
  border-bottom: 1px dotted rgba(0, 0, 0, 0.2);
}

.newsExt svg {
  margin-right: .5rem;
}

.newsExt span {
    margin-right: 1rem;
}

.news .content {
    width: 100%;
    height: 100%;
}

.latest {
    margin-bottom: 1.5rem;
}

.linkToNews {
  display: flex;
  justify-content: space-between;
}

.linkToNews a {
  color: rgba(0, 0, 0, 0.4);
}

.linkToNews a:hover {
  color: rgba(0, 0, 0, 1);
}

.artRight {
  justify-content: flex-end;
}

.artLeft {
  justify-content: flex-start;
}

