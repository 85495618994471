.boxContent {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
}
.boxContent div{
    width: 100%;
    height: 100%;
    position: relative;
    margin-bottom: 2rem;
}

.boxContent div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.contentTab {
    padding: 1rem;
}

.backgroundImg {
	width: 100%;
	min-height: calc(100vh - (0rem));
	height: auto;
	position: fixed;
    top: 0;
    left: 0;
	z-index: 1;
}

.backgroundImg img {
	width: 100vw;
	height: 100vh;
	object-fit: cover;
}
