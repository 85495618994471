
.jpWrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  margin-top: -120px;
}

.jpWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
