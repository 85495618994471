.subTitle {
	font-weight: bold;
	/* font: bold 16px Helvetica; */
}

.title {
	margin-bottom: 2rem;
}
.backgroundImg {
	width: 100%;
	min-height: calc(100vh - (0rem));
	height: auto;
	position: fixed;
    top: 0;
    left: 0;
	z-index: 1;
}

.backgroundImg img {
	width: 100vw;
	height: 100vh;
	object-fit: cover;
}
