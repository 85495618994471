.contentNews {
  display: flex;
}

.allNews {
  width: calc(70% - 2rem);
  margin-right: 2rem;
}

.latestNews {
  width: 30%;
  padding: 0 1rem 1rem 1rem;
  border-left: 1px solid #e5e5e5;
}

.news {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
}

.newsImg {
  width: 100px;
  height: 100px;
  position: relative;
  margin-right: 1rem;
}

.newsContent {
  position: relative;
  width: 100%;
  height: 100%;
}

.news .content p {
  width: 100%;
  /* word-wrap: break-word; */
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.isPreview {
  width: calc(100% - 100px);
}

.news img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.newsTitle {
  font-size: var(--hdText);
  font-weight: bold;
  margin-bottom: 8px;
  line-height: 1.3em;
  color: #333333;
}

.newsExt {
  display: flex;
  position: relative;
  align-items: center;

  font-size: 11px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 8px;
  padding: 4px 0px 0px;
  border-top: 1px dotted rgba(0, 0, 0, 0.2);
  border-bottom: 1px dotted rgba(0, 0, 0, 0.2);
}

.newsExt svg {
  margin-right: 0.5rem;
}

.newsExt span {
  margin-right: 1rem;
}

.news .content {
  width: 100%;
  height: 2.75rem;
  overflow: hidden;
  /* white-space: nowrap; */
  /* text-overflow: ellipsis; */
}

.news .content p {
  margin-bottom: unset;
}

.latest {
  margin-bottom: 1.5rem;
}

.latest h4 {
}

.pagination {
  display: flex;
  margin-top: 2rem;
  float: right;
}
.pagination ul {
  display: flex;
  margin: auto 1rem auto 0;
}
.pagination ul li {
  background-color: #fff;
  height: 1.6rem;
  width: 100%;
  /* border: 1px solid #e3e3e3; */
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.pagination ul li a {
  background-color: transparent;
  border: unset;
  height: 1.6rem;
  width: 100%;
  border: 1px solid #e3e3e3 !important;
  border-radius: 12px !important;
  display: flex;
  align-items: center;
}
