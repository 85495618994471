@font-face {
  font-family: 'Ubuntu';
  src:  url('./assets/Ubuntu-Regular.ttf') format('ttf'),
  url('./assets/Ubuntu-Bold.ttf') format('ttf'),
  url('./assets/Ubuntu-Italic.ttf') format('ttf')
}
:root {
  /* COLORS  */
  /* rest */
  --body: #e5e5e5;
  --black: #222;
  --white: #fff;
  --red-light: #f8d7da;
  --red-dark: #842029;
  --green-light: #d1e7dd;
  --green-dark: #0f5132;

  /* box shadows */
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);

  /* typography */
  --defaultFontSize: 87.5%;
  --headingFont: "Roboto", monospace;
  --bodyFont: "Open Sans", sans-serif;
  --fhdText: 1.2em;
  --hdText: 1em;
  --smallText: 0.8em;
  --extraSmallText: 0.7em;

  /* rest */
  --textColor: var(--black);
  --borderRadius: 0.25rem;
  --letterSpacing: 1px;
  --transition: 0.3s ease-in-out all;
  --max-width: 100%;

  /* font-family: 'Ubuntu', Helvetica, Arial, sans-serif !important; */
  font-family: 'Roboto', Helvetica, Arial, sans-serif !important;
}

body {
  background: var(--body);
}

#root {
  background: var(--body);
}

/* turn off if you are working on local */
/* #root > div > div > div > div {
  position: relative;
  background: #036;
  z-index: 1;
} */
/* #root > div > div > div > div > div:nth-child(1) {
  background: unset !important;
} */
/* */

html {
  font-size: var(--defaultFontSize);
}
.cKJzHK {
  height: auto;
  background: #e5e5e5;
}

@media only screen and (min-width: 768px) {
  :root {
    --defaultFontSize: 112.5%;
  }
}

@media only screen and (min-width: 576px) {
  :root {
    --defaultFontSize: 100%;
  }
}
/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bfb5af;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

small,
.text-small {
  font-size: var(--smallText);
}

a {
  color: #000000;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}
div.main-wrapper {
  width: 100%;
  height: 100%;
  background: #E5E5E5;
}
main.section {
  /* only works on server */
  z-index: 2;
}
main.section, div.section {
  width: 960px;
  /* width: 70vw; */
  max-width: var(--max-width);
  min-height: calc(100vh - (0rem));
  height: 100%;
  /* padding-top: 120px; */
  margin: 0 auto;
  background: #ffffff;
  position: relative;
}

main.section .content, div.section .content {
  padding: 1rem 2.5rem 2.5rem 2.5rem;
}

h1.title-section,
h2.title-section,
span.title-section,
.subtitle-section {
  position: relative;
  padding: 0.5rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

h1.title-section::after,
h2.title-section::after,
span.title-section::after,
.subtitle-section::after {
  content: "";
  height: 1px;
  width: 40px;
  background-color: #4b69de;
  display: block;
  position: absolute;
  bottom: -1px;
}

.subtitle-section {
  font-size: 14px;
  color: #666666;
}

.nav-link.active {
  font-weight: bold;
  -webkit-transition: font-weight 0.1s ease-in-out;
  -moz-transition: font-weight 0.1s ease-in-out;
  transition: font-weight 0.1s ease-in-out;
}

#backgroundImg
  > .slick-slider
  > .slick-list
  > .slick-track
  > .slick-slide
  > div {
  position: relative;
  width: 100%;
  height: 100%;
}

#backgroundImg div {
  width: 100%;
  height: 100%;
}

.JP_slider .slick-arrow {
  display: block !important;
  background: rgba(0, 0, 0, 0.3);
  padding: 8px;
  border-radius: 4px;
}

.JP_slider .slick-prev {
  left: 1rem;
  top: 9rem;
  color: white;
  height: 40px;
  width: 40px;
  z-index: 2;
}

.JP_slider .slick-next {
  right: 1rem;
  top: 9rem;
  color: white;
  height: 40px;
  width: 40px;
  z-index: 2;
}

.JP_slider .slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 40px;
  line-height: 1;
  opacity: 0.5;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.JP_slider .slick-track,
.slick-track > .slick-slide > div {
  height: 100%;
}

.JP_slider .slick-prev:hover, .JP_slider .slick-prev:focus, .JP_slider .slick-next:hover, .JP_slider .slick-next:focus {
  color: white;
  outline: none;
  background: rgba(0, 0, 0, 0.3);
}

#JP_co img {
  width: 100vw !important;
  height: 100vh !important;
  object-fit: cover;
}

#JP_ContactUs .leaflet-div-icon {
  width: auto !important;
  height: auto !important;
  background: transparent;
  border: none;
  transform: translate3d(282px, 90px, 0px) !important;
}