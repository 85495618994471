@media screen and (max-width: 414px) {
  
  .jpLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: auto;
    /* height: 100vh; */
  }

  .jpLogo img {
    width: 75%;
  }

  .navItemMobile {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    transform: translateX(-5%); /* Center vertically */
  }

  .navMenuMobile {
    background-color: rgba(0, 56, 107, .8);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 105vh;
    position: fixed;
    top: 0;
    right: -100%; /* Start offscreen to the right */
    opacity: 1;
    transition: right 0.4s ease-in-out, opacity 0.4s ease-in-out;
    z-index: auto;
    padding-top: 7rem;
    justify-content: flex-start;
  }
  
  .navMenuMobile.active {
    background-color: rgba(0, 56, 107, .8);
    right: 0; /* Slide in from the right */
    opacity: 1;
    z-index: auto;
    margin: 0rem;
    margin-top: 0rem;
    padding-top: 7rem;
    height: 105vh;
    justify-content: flex-start;
    transition: right 0.4s ease-in-out, opacity 0.4s ease-in-out;
  }
  

  .navLinks {
    text-align: center;
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .navLinksMobile:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .menuIcon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.5rem;
    cursor: pointer;
  }

  .navIcon {
    color: #fff;
    font-size: 1.8rem;
  }

  .navLinksMobile {
    display: block;
    text-align: center;
    margin: 0.5rem auto;
    border-radius: 4px;
    width: 80%;
    font-size: 1.2rem;
    background-color: transparent;
    color: #fff;
    padding: 10px 15px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .navLinksMobile li:nth-child(2) {
    color: #fff;
    font-weight: bold;
  }

  .navLinksMobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }

  .navItemContent {
    display: flex;
    align-items: center;
  }

  .titleContainer {
    flex: 1;
    text-align: center;
    margin-bottom: 5px;
  }

  .childItemsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .childCard {
    margin-bottom: 5px;
  }

  .childLink {
    text-align: center;
    border-radius: 4px;
    font-size: 1.2rem;
    background-color: transparent;
    color: #fff;
    padding: 10px 15px;
    transition: all 0.3s ease-out;
    width: fit-content;
    border: none;
    outline: none;
  }

  .childLink:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }

  .navbar {
    background-color: rgba(0, 56, 107, .8);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }

  .navbarContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    max-width: 100vw;
    width: 100%;
    padding: 0 1rem;
  }

    /* Add any necessary styles for the back button */
  .backButton {
    color: #fff;
    font-size: 1.5rem;
  }

  .backIcon {
    margin-right: 5px;
  }

  .logoAndBack {
    display: flex;
    align-items: center;
  }

  /* .logoAndBack > * {
    margin-right: 5px;
  } */

}


