.boxContent {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
}

.boxContent > div:first-child {
    width: calc(70% - 4rem);
    position: relative;
}

.boxContent > div:last-child {
    width: 30%;
    margin-left: 2rem;
    padding: 0 2rem;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.boxContent img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width: 414px) {

.boxContent {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   /* padding-top: 1rem; */
}

.boxContent > div:first-child {
    width: 100%;
}

.boxContent > div:last-child {
    width: 100%;
    padding-top: 2rem;
    border-left: none;
    margin-left: -4rem;
}

}