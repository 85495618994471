.backgroundImg {
	width: 100%;
	min-height: calc(100vh - (0rem));
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;
}

.backgroundImg img {
	width: 100vw;
	height: 100vh;
	object-fit: cover;
}
.contentWrapper {
	display: flex;
	width: 100%;
	height: 100%;
	margin-bottom: 1rem;
}
.boxContent {
	display: flex;
	flex-direction: column;
	width: 80%;
}

.contentImage {
	width: 100px;
	height: 100px;
	position: relative;
	margin-right: 1.5rem;
}
.contentImage img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.boxImage {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	margin: 2rem 0;
}

.boxImage img {
	width: 100%;
    height: 100%;
	object-fit: cover;
}

.articleTitle {
	font: bold 16px 'Roboto', Helvetica, Arial, sans-serif;;

}