h2.subTitle {
    font-size: var(--hdText);
    color: #666666;
    padding: 0 0 1rem 0;
}

.boxContent > div {
    padding: 2rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
}
.boxContent img{
    max-width: 100%;
}

.backgroundImg {
	width: 100%;
	min-height: calc(100vh - (0rem));
	height: auto;
	position: fixed;
    top: 0;
    left: 0;
	z-index: 1;
}

.backgroundImg img {
	width: 100vw;
	height: 100vh;
	object-fit: cover;
}
