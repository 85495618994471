.boxcontent div{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
}

.boxcontent:last-child div {
    justify-content: flex-start;
}