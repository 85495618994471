.jpHead {
  width: 960px;
  height: auto;
  position: relative;
  margin: 0 auto;
  z-index: 1000;
}

.jpLogo {
  width: 100%;
  height: 80px;
  background-color: rgba(0, 56, 107, 0.8);
  background-position: center center;
  display: flex;
}

.jpLogo img {
  margin: auto;
}

.jpNav {
  width: 100%;
  height: 40px;
  background-color: rgba(255, 255, 255, 1);
  background-position: center center;
  display: flex;
  align-items: center;
  padding: 0 1.5em;
  cursor: default;
  justify-content: center;
}

.jpNav div {
  /* margin-right: 1.5rem; */
  position: relative;
  padding: .30rem .75rem;
}

.jpNav ul {
  display: inline;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.jpNav ul li {
  display: inline-block;
  padding: 0.25rem 0;
}
.jpNav ul li:hover {
  background: white;
}
/* .jpNav ul li:hover ul {
  display: block;
  border-top: 1px solid #4B69DE;
} */
.jpNav div.hasItm:hover ul li ul {
  display: block;
  border-top: 1px solid #4b69de;
}
.jpNav ul li:hover ul::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-bottom: 5px solid #4b69de;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  position: absolute;
  top: -6px;
  left: 6px;
}
.jpNav ul li ul {
  position: absolute;
  /* width: 100%; */
  /* width: auto; */
  width: 10rem;
  display: none;
  margin-top: 0.3rem;
  z-index: 1000;
}
.jpNav ul li ul li {
  background: white;
  display: block;
  /* padding: .25rem 0; */
}
.jpNav ul li ul li span {
  display: block !important;
}
.jpNav ul li ul li:hover {
  background-color: rgb(211, 211, 211);
}

.jpNav > div > ul > li > ul > li > span {
  padding: 0.25rem 0.5rem;
}

.jpNav > div > ul > li > span {
  padding: 0.5rem 0.5rem;
}

.jpNav .itmChilds {
  height: auto;
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 20%);
}

.jpNav .itmChilds li {
  padding: 0.25rem 0.5rem;
  color: black;
  text-decoration: none;
}

.jpNav a {
  text-decoration: none;
  color: black;
}

.jpNav a:hover {
  text-decoration: none;
  color: black;
}

.jpNav span {
  /* padding: .5rem .5rem; */
}

.activeLink {
  border-top: 1.5px solid #4B69DE;
  color: #4B69DE;
}

.activeLink > ul > li > a > span {
  color: #4B69DE;
}

.activeChild {
  color: #4B69DE;
}

.navbar {
  /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
  background-color: rgba(0, 56, 107, .8);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbarContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 80px;
  max-width: 100vw;
}

.navbarLogo {
  color: rgb(237, 195, 8);
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin: -0.1rem;
}

.navMenuMobile {
  display: flex;
  list-style: none;
  text-align: center;
  justify-content: end;
  margin: 0.2rem;
  font-size: 1rem;
}

.navLinksMobile {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 0.5rem;
}

.navLinks:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.navIcon {
  color: #fff;
  font-size: 2rem;
}

.menuIcon {
  display: none;
}

@media screen and (max-width: 820px), screen and (max-width: 768px) {
  .jpHead {
    width: 100%;
    text-align: center;
  }
  
  .jpLogo {
    justify-content: center;
    align-items: center;
  }
  
  .jpLogo img {
    max-width: 100%;
    max-height: 100%;
  }
}


@media screen and (max-width: 768px) {
  .jpHead {
    width: 100%;
    text-align: center;
  }
  
  .jpLogo {
    justify-content: center;
    align-items: center;
  }
  
  .jpLogo img {
    max-width: 100%;
    max-height: 100%;
  }

  .jpNav {
    padding: 0 1em;
  }
}
