.backgroundImg {
  width: 100%;
  min-height: calc(100vh - (0rem));
  height: 100%;
  position: absolute;
  z-index: -1;
}

.backgroundImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
