.jpRunning {
  grid-area: jpInfo;
  text-align: center;
  background: #003366 !important;
}

.jpRunning span {
  font-size: 18px;
}

.jpCurrentDate {
  grid-area: jpDate;
  display: flex;
  background: #003366 !important;
}

.jpFootage {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: auto;
  background: #003366;
  opacity: 0.7;
  z-index: 2000;
  display: grid;
  grid-template-columns: 25% 50% 25%;
  grid-template-rows: auto;
  grid-template-areas: "jpDate jpInfo .";
}

.jpFootage span {
  color: white;
  margin: auto 0;
}

.jpFootage .higlight {
  color: rgb(255, 204, 0);;
}

@media screen and (max-width: 667px) {
  .jpRunning span {
    font-size: 15px;
  }

  .jpFootage {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto;
    background: #003366;
    opacity: 0.7;
    z-index: 2000;
    /* display: grid;
    grid-template-columns: 33% 77%;
    grid-template-rows: auto;
    grid-template-areas: "jpDate jpInfo ."; */
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  
}