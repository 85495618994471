.boxContent {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    margin-bottom: 2rem;
}

.boxContent > div:last-child {
    width: calc(70% - 3rem);
    height: 100%;
    position: relative;
    margin-left: 3rem;
}

.boxContent > div:first-child {
    width: 30%;
    height: 100%;
    position: relative;
}

.contentTab {
    padding: 1rem;
}

.sliderDesc {
    margin-left: 2rem;
    top: -2rem;
}

.sliderDesc span {
    font: bold 18px Helvetica;
    color: #ffffff;
    text-shadow: 1px 3px 0 #969696, 1px 13px 5px #aba8a8, 2px 2px 2px rgba(0,0,0,0);
}

@media screen and (max-width: 414px) {

.boxContent {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding-top: 1rem;
}

.boxContent > div:last-child {
    width: 100%;
    height: 100%;
    position: relative;
    margin-left: 0rem;
    padding-top: 1rem;
}

.boxContent > div:first-child {
    width: 100%;
}

}